import './App.css';

function App() {
  return (
    // <div className="App">
    //   <header className="App-header">
    //     <img src={logo} className="App-logo" alt="logo" />
    //     <p>
    //       Edit <code>src/App.js</code> and save to reload.
    //     </p>
    //     <a
    //       className="App-link"
    //       href="https://reactjs.org"
    //       target="_blank"
    //       rel="noopener noreferrer"
    //     >
    //       Learn React
    //     </a>
    //   </header>
    // </div>
    <div className='logo'>
      <div>
        <p><img src="logo.svg" alt="Red Deer Studio Limited logo" /></p>
        <p>Red Deer Studio Limited is a registered company registered in Scotland with company number SC688139</p>
        <p>&copy; 2021 Red Deer Studio Limited</p>
      </div>
    </div>
  );
}

export default App;
